<template>
  <div>
    <v-form lazy-validation ref="form">
      <v-row>
        <v-col cols="12" sm="12" md="6" xl="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="form.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.startDate" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="form.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                label="End Date"
              ></v-text-field>
            </template>
            <v-date-picker v-model="form.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="3">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model.number="form.month"
            label="Month"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="form.items"
            dense
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.month="{ item }">
              <v-select
                outlined
                flat
                dense
                background-color="white"
                v-model.number="item.month"
                :items="listMonth"
              ></v-select>
            </template>
            <template v-slot:item.day="{ item }">
              <v-select
                outlined
                flat
                dense
                background-color="white"
                v-model.number="item.day"
                :items="listDay"
              ></v-select>
            </template>
            <template v-slot:item.amount="{ item }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model.number="item.amount"
                persistent-hint
                :hint="formatPrice(item.amount)"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="text-right">
        <v-col cols="12">
          <!-- <v-btn color="primary" @click="getCashBudget" class="mr-2">
            generate
            <v-icon>mdi-magnify</v-icon>
          </v-btn> -->
          <v-btn color="success" @click="excel">
            export
            <v-icon>mdi-file-excel</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <!-- <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th rowspan="2">
                  Bank Dan AR
                </th>
                <th
                  v-for="(item, index) in cashBudget.months"
                  :key="index + 'a'"
                  :colspan="item.colSpan"
                >
                  {{ item.month }} {{ item.year }}
                </th>
              </tr>
              <tr>
                <th v-for="(item, index) in cashBudget.weeks" :key="index + 'b'">
                  {{ formatDate(item.start) }} s/d {{ formatDate(item.end) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bank</td>
                <td v-for="(item, index) in cashBudget.banks" :key="index + 'c'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Rekap perkiraan AR jatuh tempo</td>
                <td v-for="(item, index) in cashBudget.ars" :key="index + 'd'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Perkiraan yang akan invoice</td>
                <td v-for="(item, index) in cashBudget.invs" :key="index + 'e'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Total perkiraan penerimaan</td>
                <td v-for="(item, index) in cashBudget.totalEstimation" :key="index + 'f'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th rowspan="2">
                  Cost Dan AP
                </th>
                <th
                  v-for="(item, index) in cashBudget.months"
                  :key="index + 'g'"
                  :colspan="item.colspan"
                >
                  {{ item.month }} {{ item.year }}
                </th>
              </tr>
              <tr>
                <th v-for="(item, index) in cashBudget.weeks" :key="index + 'h'">
                  {{ formatDate(item.start) }} s/d {{ formatDate(item.end) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Payment A/P IDR</td>
                <td v-for="(item, index) in cashBudget.paymentApIdrs" :key="index + 'i'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Payment A/P Valas</td>
                <td v-for="(item, index) in cashBudget.paymentApValas" :key="index + 'j'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Gaji</td>
                <td v-for="(item, index) in cashBudget.salaries" :key="index + 'k'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>PPH</td>
                <td v-for="(item, index) in cashBudget.pph" :key="index + 'l'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>PPN</td>
                <td v-for="(item, index) in cashBudget.ppn" :key="index + 'm'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Subcont</td>
                <td v-for="(item, index) in cashBudget.subconts" :key="index + 'n'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Telepon</td>
                <td v-for="(item, index) in cashBudget.telephones" :key="index + 'o'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Listrik</td>
                <td v-for="(item, index) in cashBudget.energies" :key="index + 'p'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>IPL</td>
                <td v-for="(item, index) in cashBudget.ipl" :key="index + 'q'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Hutang Shareholder</td>
                <td v-for="(item, index) in cashBudget.shareholders" :key="index + 'r'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>BPJS-TK</td>
                <td v-for="(item, index) in cashBudget.bpjsTk" :key="index + 's'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>BPJS Kesehatan</td>
                <td v-for="(item, index) in cashBudget.bpjsHealth" :key="index + 't'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Outsourcing</td>
                <td v-for="(item, index) in cashBudget.outsourcing" :key="index + 'u'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>PIB</td>
                <td v-for="(item, index) in cashBudget.pib" :key="index + 'v'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Bunga Pinjaman</td>
                <td v-for="(item, index) in cashBudget.interests" :key="index + 'w'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Bank Garansi</td>
                <td v-for="(item, index) in cashBudget.guarantees" :key="index + 'x'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Kasbon</td>
                <td v-for="(item, index) in cashBudget.kasbons" :key="index + 'y'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>DO Oustanding IDR</td>
                <td v-for="(item, index) in cashBudget.doOutstandiIdrs" :key="index + 'z'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>DO Outstanding Valas</td>
                <td v-for="(item, index) in cashBudget.doOutstandingValas" :key="index + 'aa'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>THR</td>
                <td v-for="(item, index) in cashBudget.thr" :key="index + 'ab'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Outstanding PO IDR</td>
                <td v-for="(item, index) in cashBudget.outstandingPoIdrs" :key="index + 'ac'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Outstanding PO Valas</td>
                <td v-for="(item, index) in cashBudget.outstandingPoValas" :key="index + 'ad'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Cicilan Mobil</td>
                <td v-for="(item, index) in cashBudget.instalments" :key="index + 'ae'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td v-for="(item, index) in cashBudget.totalCost" :key="index + 'af'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th rowspan="2">
                  Debt Dan Result
                </th>
                <th
                  v-for="(item, index) in cashBudget.months"
                  :key="index + 'a'"
                  :colspan="item.colspan"
                >
                  {{ item.month }} {{ item.year }}
                </th>
              </tr>
              <tr>
                <th v-for="(item, index) in cashBudget.weeks" :key="index + 'b'">
                  {{ formatDate(item.start) }} s/d {{ formatDate(item.end) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hutang Bank</td>
                <td v-for="(item, index) in cashBudget.debts" :key="index + 'ag'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
              <tr>
                <td>Perhitungan Net</td>
                <td v-for="(item, index) in cashBudget.nets" :key="index + 'ah'">
                  {{ formatPrice(item.total) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "cash-budget",
  computed: {
    listDay() {
      let array = [];
      for (let i = 0; i <= 30; i++) {
        array.push(i + 1);
      }
      return array;
    },
  },
  data() {
    return {
      menuStartDate: false,
      menuEndDate: false,
      cashBudget: {},
      form: {
        items: [],
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment()
          .add(3, "months")
          .endOf("month")
          .format("YYYY-MM-DD"),
        month: 3,
      },
      listMonth: [
        {
          text: "Januari",
          value: 1,
        },
        {
          text: "Februari",
          value: 2,
        },
        {
          text: "Maret",
          value: 3,
        },
        {
          text: "April",
          value: 4,
        },
        {
          text: "Mei",
          value: 5,
        },
        {
          text: "Juni",
          value: 6,
        },
        {
          text: "Juli",
          value: 7,
        },
        {
          text: "Agustus",
          value: 8,
        },
        {
          text: "September",
          value: 9,
        },
        {
          text: "Oktober",
          value: 10,
        },
        {
          text: "November",
          value: 11,
        },
        {
          text: "Desember",
          value: 12,
        },
      ],
      headers: [
        {
          id: 1,
          text: "Type",
          sortable: false,
          value: "type",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Month",
          value: "month",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Day",
          value: "day",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Amount",
          value: "amount",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD MMMM");
    },
    async getCashBudget() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("report/cashBudgetV2", this.form)
          .then(response => {
            this.cashBudget = response.data;
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    async getCashBudgetItems() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/cashBudgetItem")
        .then(response => {
          this.form.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/cashBudgetExcelV2", this.form)
        .then(response => {
          fileDownload(response.data, `report-cash-budget-${this.form.startDate}.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getCashBudgetItems();
  },
};
</script>

<style></style>
